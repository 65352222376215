export const text = {
  instruction: `<h3><b>HOW TO PLAY</b></h3>
        <p>
          <b>The Object: </b> Slide the Red Car through the exit to freedom!
        </p>
        <p>
          <b>Setup:</b> Select a challenge card and place the cars and trucks on the traffic grid as indicated by the illustration.
        </p>
        <p>
          <b>To Play:</b> Slide the blocking cars and trucks in their lanes-up and down, left and right-until the path is clear for the red car to escape. Vehicles can only slide forward & backward, not sideways.
        </p>
        <p>
          <b>One Rule: </b> No lifting the cars or trucks off the traffic grid surface. Stay in your lanes!
        </p>
        <p>
          <b>If You're Stuck:</b> Just dump the pieces out of the grid and start over.
        </p>
        <p>
          <b>If Your Brain Stalls:</b> The complete move sequence solution is printed on the back of each challenge card.
        </p>
      </div>`,
  instructionDescription: ``,
  highScoreButton: `Highscore`,
  resetButton: `Reset Level`,
  solutionButton: `Show Solution`,
  rulesButton: `Rules`,
  nextButton: `Next`,
  nextLevel: `Next Level`,
  startOver: `Start Over`,
  storeText: `Available in toy stores.`,
  storeLink: 'https://www.amazon.com/gp/product/B00000DMER/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00000DMER&linkCode=as2&tag=thin05b-20&linkId=537EFF22ROBKCNC5',
  levelFinished: {
    headline: `Congratulations!`,
    p1: 'You have passed the level',
    p2: '',
    p3: `Enter your initials to appear on the list:`,
  },
  placeText: 'Place',
  moves: 'moves',
};
